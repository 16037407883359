<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Pferde</h1>
        <p>
          Auf unserem Hof leben vier Pferde. Sie sind von unterschiedlicher und gemischter Rasse.
        </p>

        <h2>Blinde Stute</h2>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-sacageweaBlind.jpg">
          <img src="../../assets/images/sacageweaBlind.jpg">
        </picture>

        <p>
          Eine der Stuten, Sacagewea, erblindete aus einem unbekannten Grund und wird seitdem in einer kontrollierten
          Umgebung gehalten. Zum einen ist das Gelände, in dem die Pferde die meiste Zeit verbringen, nicht flach und
          eben, und wir wollen nicht, dass Sacagewa sich ein Bein bricht. Sich ein Bein zu brechen wäre für ein Pferd
          ein großes Problem. Aus einem anderen Grund schützen wir sie vor Rangordnungskonflikten , da sie früher die
          Leitstute der kleinen Herde war.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "HorsesDE",
  components: {
    Sidebar,
  },

};
</script>
